
/*!
 *  Fuse content list item
 *
 *  @prop string className - Append a class name.
 *  @prop object content - Content object.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./contentlistitem.scss";

import API from "Class/API";
import Fuse from "Class/Fuse";
import {ObjectCompare} from "Functions";

import Counter from "Components/Feedback/Counter";
import Icon from "Components/Layout/Icon";
import IconButton from "Components/UI/IconButton";
import Link from "Components/UI/Link";
import Preview from "Components/Layout/Preview";

class ContentListItem extends React.Component
{
    constructor(props)
    {
        super(props);
        this.Block = false;
        this.state =
        {
            content: false,
            contentObject: false,
            error: false,
            loading: false
        };
    }

    /**
     * Load content on mount.
     * @return void
     */

    componentDidMount()
    {
        const {content, contentObject} = this.props;
        const {content: fuseContent} = content;
        if (contentObject)
        {
            this.setState({
                content: contentObject.id,
                contentObject
            });
        }
        else
        {
            this.Load(fuseContent);
        }
    }

    /**
     * Update content when a new ID is received.
     * @return void
     */

    componentDidUpdate(prevProps)
    {
        if (this.Block)
        {
            return;
        }
        const {content, contentObject} = this.props;
        const {content: currentContent, contentObject: currentContentObject} = this.state;
        const {content: fuseContent} = content;
        const NewObject = !ObjectCompare(contentObject, currentContentObject);
        let Content = fuseContent;
        while (Content && typeof Content === "object")
        {
            Content = Content[0];
        }
        if (contentObject && NewObject)
        {
            this.Block = true;
            this.setState({
                content: contentObject.id,
                contentObject
            }, () => this.Block = false);
        }
        else if (!contentObject && !ObjectCompare(Content, currentContent))
        {
            this.Load(fuseContent);
        }
    }

    /**
     * Load content from the Fuse API.
     * @param array|integer item - [contentId, type] or just contentId.
     * @return void
     */

    Load = (item) =>
    {
        if (!item)
        {
            this.Block = true;
            this.setState({
                content: 0,
                error: false,
                loading: false
            }, () => this.Block = false);
        }
        else if (typeof item === "object" && typeof item[0] === "object")
        {
            this.Load(item[0]);
        }
        else if (typeof item === "object")
        {
            this.Block = true;
            this.setState({
                content: item[0],
                error: false,
                loading: true
            }, () => this.Block = false);
            this.LoadContent(item[0], item[1]);
        }
        else
        {
            this.Block = true;
            this.setState({
                content: item,
                error: false,
                loading: true
            }, () => this.Block = false);
            /**
             * Check content type in the backend DB before fetching.
             */
            API.Request("content/cache-read", {id: item}, response =>
            {
                const {cache, error} = response;
                if (error)
                {
                    this.setState({
                        error: true,
                        loading: false
                    });
                }
                else
                {
                    this.LoadContent(item, cache.type);
                }
            });
        }
    }

    /**
     * Load content data from fuse.
     * @param integer id - The content id.
     * @param string type - The content type.
     * @return void
     */

    LoadContent = (id, type) =>
    {
        Fuse.Content(id, type, content =>
        {
            this.Block = true;
            if (!content)
            {
                this.setState({
                    error: true,
                    loading: false
                }, () => this.Block = false);
            }
            else
            {
                this.setState({
                    loading: false,
                    contentObject: content
                }, () => this.Block = false);
            }
        });
    }

    Tray = () =>
    {
        const {contentObject, error, loading} = this.state;
        if (!contentObject || error || loading)
        {
            return "";
        }
        const {
            comments_count: comments,
            likes_count: likes,
            shares_count: shares,
            total_view_count: views
        } = contentObject.raw;
        return (
            <div className="ContentListItemTray">
                <Icon
                    className="ContentListItemTrayItem"
                    feather="Eye"
                />
                <Counter className="ContentListItemTrayCounter" count={views}/>
                <IconButton
                    className="ContentListItemTrayItem"
                    feather="Heart"
                />
                <Counter className="ContentListItemTrayCounter" count={likes}/>
                <IconButton
                    className="ContentListItemTrayItem"
                    feather="MessageCircle"
                />
                <Counter className="ContentListItemTrayCounter" count={comments}/>
                <IconButton
                    className="ContentListItemTrayItem"
                    feather="Share2"
                />
                <Counter className="ContentListItemTrayCounter" count={shares}/>
            </div>
        );
    }

    render()
    {
        const {className, content, icon, noStyle, overlay, showInfo, showPreview, showTray, target} = this.props;
        const {content: contentId, contentObject, error, loading} = this.state;
        const {externalLink, image, label, url, urlBlank, urlBlank2} = content;
        const CA = noStyle ? [] : ["ContentListItem"];
        if (error && (!externalLink || !url))
        {
            CA.push("ErrorItem");
        }
        if (showTray)
        {
            CA.push("HasTray");
        }
        if (className)
        {
            CA.push(className);
        }
        let PreviewUrl, Label, Url;
        let Description = "";
        let Target = target || (urlBlank2 ? "_blank" : "_top");
        let Type = "File";
        if (loading)
        {
            Description = "Loading...";
            PreviewUrl = image ? parseInt(image[0], 10) : 0;
            Label = label;
        }
        else if (!contentId)
        {
            Description = externalLink ? "External link" : "Link";
            PreviewUrl = image ? parseInt(image[0], 10) : 0;
            Label = label;
            Target = target || ((externalLink && urlBlank) ? "_blank" : "_top");
            Type = "ExternalLink";
            Url = url;
        }
        else if (contentObject)
        {
            const {id, name, preview, raw, type} = contentObject;
            const {created_at} = raw || {};
            Description = `Posted ${created_at} ago`;
            PreviewUrl = (typeof image === "object" && image.length) ? parseInt(image[0], 10) : preview;
            Label = label || name;
            Type = type;
            Url = Fuse.ContentUrl(id, type);
        }
        return (
            <Link className={CA.join(" ")} href={Url} target={Target}>
                {showPreview ? <Preview className="ItemPreview" content={Type} image={PreviewUrl} overlay={overlay}/> : ""}
                <div className="ItemContent">
                    <div className="ItemName">{icon ? <Icon className="ItemIcon" feather={icon}/> : ""}{Label}</div>
                    {showTray ? this.Tray() : (showInfo ? <div className="ItemInfo">{Description}</div> : "")}
                </div>
            </Link>
        );
    }
}

ContentListItem.propTypes =
{
    className: PropTypes.string,
    content: PropTypes.object,
    contentObject: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    target: PropTypes.string
};

ContentListItem.defaultProps =
{
    className: "",
    content: {},
    contentObject: false,
    icon: "",
    noStyle: false,
    overlay: true,
    showInfo: true,
    showPreview: true,
    showTray: false,
    target: ""
};

export default ContentListItem;