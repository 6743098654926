/**!
 *  Article editor.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */
import React from "react";
import "./articles.scss";
import API from "Class/API";
import Globals from "Class/Globals";
import {ArrayClone, ObjectCompare, RandomToken} from "Functions";
import Center from "Components/Layout/Center";
import IconButton from "Components/UI/IconButton";
import Item from "Components/UI/Item";
import TabMenu from "Components/UI/TabMenu";
import ViewArticlesEditor from "./Views/Editor";
import ViewArticlesHome from "./Views/Home";
import Icon from "Components/Layout/Icon";

class ViewArticles extends React.Component
{
    constructor(props)
    {
        super(props);
        this.DemoMode = false;
        this.Mounted = false;
        this.state =
        {
            article: false,
            changed: false,
            iteration: 0,
            lastTab: "new",
            loading: false,
            original: false,
            reset: 0,
            selectedTab: "new"
        };
    }

    componentDidMount()
    {
        this.Mounted = true;
    }

    componentWillUnmount()
    {
        this.Mounted = false;
    }

    ClearLocalDraft = (articleId) =>
    {
        const List = this.LoadDrafts();
        delete List[articleId];
        Globals.StorageRemove(this.GetKey(articleId));
        Globals.Storage(this.GetKeyDrafts(), JSON.stringify(List));
    }

    CloneEditorState = (editorState, overwrite = {}) =>
    {
        const {
            access,
            appearance,
            blocks,
            description,
            fullWidth,
            id,
            image,
            images,
            permission,
            title,
            type,
            updated
        } = editorState;
        const ClonedState = {
            access: ArrayClone(access),
            appearance: ArrayClone(appearance),
            blocks: ArrayClone(blocks),
            description,
            fullWidth,
            id,
            image: ArrayClone(image),
            images: ArrayClone(images),
            permission: permission || 2,
            title,
            type,
            updated
        };
        for (let key in overwrite)
        {
            ClonedState[key] = overwrite[key];
        }
        return ClonedState;
    }

    ConfirmNavigation = (callback) =>
    {
        const {changed, selectedTab} = this.state;
        if (changed && selectedTab === "editor")
        {
            Globals.DialogCreate({
                confirmLabel: "Yes",
                message: "This article has unsaved changes. The changes will be preserved in your local draft, but you will need to save it for the changes to propagate to other users and devices. Do you still want to continue?",
                onConfirm: callback,
                title: "Confirm navigation",
                type: "confirm"
            });
        }
        else
        {
            callback();
        }
    }

    DeleteArticle = (articleId) =>
    {
        const {article, iteration} = this.state;
        this.setState({loading: true});
        this.ClearLocalDraft(articleId);
        API.Request("articles/delete", {id: articleId}, () =>
        {
            if (article && article.id === articleId)
            {
                this.setState({article: false, iteration: iteration + 1, loading: false});
            }
            else
            {
                this.setState({iteration: iteration + 1, loading: false});
            }
        });
    }

    EditorStateChanged = (editorState) =>
    {
        const {original} = this.state;
        if (original === false)
        {
            return true;
        }
        if (original === true)
        {
            return -1;
        }
        const {
            access: ac1,
            appearance: ap1,
            blocks: b1,
            description: d1,
            fullWidth: f1,
            image: i1,
            title: ti1,
            type: ty1
        } = editorState;
        const {
            access: ac2,
            appearance: ap2,
            blocks: b2,
            description: d2,
            fullWidth: f2,
            image: i2,
            title: ti2,
            type: ty2
        } = original;
        if (
            d1 !== d2 || f1 !== f2 || ti1 !== ti2 || ty1 !== ty2 ||
            !ObjectCompare(b1, b2) ||
            !ObjectCompare(ap1, ap2) ||
            !ObjectCompare(ac1, ac2) ||
            !ObjectCompare(i1, i2)
        )
        {
            return true;
        }
        return false;
    }

    GetKey = (articleId) =>
    {
        return `article-${articleId}`;
    }

    GetKeyDrafts = () =>
    {
        return "article-drafts-rd23";
    }

    LoadDrafts = () =>
    {
        let List = {};
        const Json = Globals.Storage(this.GetKeyDrafts());
        if (!Json)
        {
            return List;
        }
        try
        {
            List = JSON.parse(Json);
        }
        catch(e)
        {
            console.error("Unable to load drafts list.", Json);
            return {};
        }
        return List;
    }

    LoadEditorState = (articleId) =>
    {
        let EditorState = {};
        const Json = Globals.Storage(this.GetKey(articleId));
        if (!Json)
        {
            return EditorState;
        }
        try
        {
            EditorState = JSON.parse(Json);
        }
        catch(e)
        {
            console.error("Unable to load editor state.");
        }
        EditorState.id = articleId;
        return EditorState;
    }

    OnBack = (e) =>
    {
        const {lastTab} = this.state;
        this.OnTab(e, lastTab);
    }

    OnClearDraft = () =>
    {
        Globals.DialogCreate({
            confirmLabel: "Clear draft",
            message: "Are you sure that you to reset the article to its' last saved state?",
            onConfirm: () =>
            {
                const {article, original, reset} = this.state;
                this.ClearLocalDraft(article.id);
                const ResetState = this.CloneEditorState(original);
                this.setState({article: ResetState, reset: reset + 1});
            },
            title: "Clear draft",
            type: "confirm"
        });
    }

    OnClearNew = () =>
    {
        Globals.DialogCreate({
            confirmLabel: "Clear draft",
            message: "This article has not been saved. Do you want to delete your local draft?",
            onConfirm: () =>
            {
                const {article, reset} = this.state;
                this.ClearLocalDraft(article.id);
                this.setState({
                    article: false,
                    original: false,
                    reset: reset + 1,
                    selectedTab: "new"
                });
            },
            title: "Clear draft",
            type: "confirm"
        });
    }

    OnClose = () =>
    {
        this.ConfirmNavigation(Globals.CloseArticleEditor);
    }

    OnCreateNew = () =>
    {
        this.setState({
            article: {id: RandomToken(10), permission: 2},
            original: false,
            selectedTab: "editor"
        });
    }

    OnDelete = (id) =>
    {
        Globals.DialogCreate({
            confirmLabel: "Delete article",
            message: "Are you sure that you want to delete the selected article?",
            onConfirm: () =>
            {
                this.DeleteArticle(id);
            },
            title: "Delete article",
            type: "confirm"
        });
    }

    OnDuplicate = (article, isDraft) =>
    {
        const {selectedTab} = this.state;
        const {access, title, type} = article;
        const Id = RandomToken(10);
        const EditorState = this.CloneEditorState(article, {
            access: type === "template" ? [] : access,
            id: Id,
            permission: 2,
            title: (selectedTab !== "new" && title) ? `${title} (Copy)` : "",
            type: "article"
        });
        this.SaveEditorState(EditorState, Id);
        this.setState({article: EditorState, original: false, selectedTab: "editor"});
    }

    OnLoad = (article, isDraft) =>
    {
        if (!isDraft)
        {
            this.setState({article, original: this.CloneEditorState(article), selectedTab: "editor"});
        }
        else
        {
            // Set original to 'true' while loading from backend to signal pending status.
            this.setState({article, original: true, selectedTab: "editor"});
            API.Request("articles/original", {id: article.id}, response =>
            {
                if (!this.Mounted)
                {
                    return;
                }
                const {data, error} = response || {};
                if (!data || error)
                {
                    this.setState({original: false});
                }
                else
                {
                    data.id = article.id;
                    this.setState({original: data});
                }
            });
        }
    }

    OnEdit = (editorState, id) =>
    {
        if (!this.Mounted)
        {
            return;
        }
        const {original} = this.state;
        const Changed = this.EditorStateChanged(editorState);
        if (Changed === true)
        {
            this.SaveEditorState(editorState, id);
        }
        else if (Changed === false)
        {
            this.ClearLocalDraft(id);
            editorState.updated = original ? original.updated : 0;
        }
        this.setState({article: editorState, changed: Changed});
    }

    OnTab = (e, selectedTab) =>
    {
        this.ConfirmNavigation(() =>
        {
            const {lastTab} = this.state;
            this.setState({
                lastTab: selectedTab !== "editor" ? selectedTab : lastTab,
                selectedTab
            });
        });
    }

    OnSave = (id) =>
    {
        const {article} = this.state;
        const Original = this.CloneEditorState(article);
        this.ClearLocalDraft(id);
        this.setState({changed: false, original: Original});
    }

    SaveDraft = (articleId, time) =>
    {
        const List = this.LoadDrafts();
        List[articleId] = time;
        Globals.Storage(this.GetKeyDrafts(), JSON.stringify(List));
    }

    SaveEditorState = (editorState, articleId) =>
    {
        const Now = (new Date()).getTime();
        this.SaveDraft(articleId, Now);
        editorState.updated = Now;
        editorState.local = 1;
        Globals.Storage(this.GetKey(articleId), JSON.stringify(editorState));
        return Now;
    }

    render()
    {
        const {article, changed, iteration, original, reset, selectedTab} = this.state;
        let Content;
        switch (selectedTab)
        {
            case "editor":
                Content = article ? (
                    <ViewArticlesEditor
                        {...article}
                        changed={changed}
                        demoMode={this.DemoMode}
                        hasOriginal={typeof original === "object"}
                        key={article.id + reset}
                        onBack={this.OnBack}
                        onClearDraft={this.OnClearDraft}
                        onClearNew={this.OnClearNew}
                        onClose={this.OnClose}
                        onEdit={this.OnEdit}
                        onSave={this.OnSave}
                    />
                ) : "";
                break;
            case "load":
                Content = (
                    <ViewArticlesHome
                        drafts={this.LoadDrafts()}
                        key={`l${iteration}`}
                        onClose={this.OnClose}
                        onCreateNew={this.OnCreateNew}
                        onDelete={this.OnDelete}
                        onDuplicate={this.OnDuplicate}
                        onLoad={this.OnLoad}
                        onLoadState={this.LoadEditorState}
                        onTab={this.OnTab}
                        title="Load a previous article"
                    >
                        This is an overview of your articles and articles that have been shared with you by other users.
                    </ViewArticlesHome>
                );
                break;
            default:
                Content = (
                    <ViewArticlesHome
                        drafts={this.LoadDrafts()}
                        key={`n${iteration}`}
                        onClose={this.OnClose}
                        onCreateNew={this.OnCreateNew}
                        onDelete={this.OnDelete}
                        onDuplicate={this.OnDuplicate}
                        onLoad={this.OnLoad}
                        onLoadState={this.LoadEditorState}
                        onTab={this.OnTab}
                        templates={true}
                        title="Create a new article"
                    >
                        Use one of your available templates below or <Item className="ViewArticlesCommand" onClick={this.OnCreateNew}><Icon feather="FilePlus"/>create an empty article.</Item><br/>
                        By clicking <div className="ViewArticlesCommand"><Icon feather="Copy"/>USE</div> you are creating an article that is a copy of that template.
                    </ViewArticlesHome>
                );
        }
        return (
            <div className="ViewArticles">
                <Center
                    className="ViewArticlesAlign"
                    padding={20}
                    width={1600}
                >
                    <div className="ViewArticlesContent">
                        <div className="ViewArticlesTop">
                            <TabMenu
                                disabled={{editor: !article}}
                                items={{new: "Create New", load: "Load Previous", editor: "Editor"}}
                                onClick={this.OnTab}
                                selected={selectedTab}
                            />
                            <IconButton
                                className="ViewArticlesClose"
                                feather="X"
                                onClick={this.OnClose}
                            />
                        </div>
                        <div className="ViewArticlesTab">
                            {Content}
                        </div>
                    </div>
                </Center>
            </div>
        );
    }
}

export default ViewArticles;